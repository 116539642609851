import React, { useState, useEffect } from 'react';
import { ADDUNIVERSITY, UPDATEUNIVERSITY } from './UniversityServices';

export const FileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    // Read file as Data URL (base64 format)
    reader.onloadend = () => {
      resolve(reader.result); // Resolve the base64 string
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

const UniversityForm = ({ isEditMode, currentUniversity, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    university_name: '',
    university_code: '',
    university_type: '',
    contact_number: '',
    logo_url: '',
    location: '',
    university_email: '',
    university_address: '',
    bg_image_url: '',
    created_by: sessionStorage.getItem('user'), // Assuming created_by is the current user
    record_status: true
  });
  const [contactNumberError, setContactNumberError] = useState('');
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    if (isEditMode && currentUniversity) {
      // Populate form data if in edit mode
      setFormData({
        university_name: currentUniversity.university_name,
        university_code: currentUniversity.university_code,
        university_type: currentUniversity.university_type,
        contact_number: currentUniversity.contact_number,
        logo_url: currentUniversity.logo_url, // Assuming logo is a URL for edit
        location: currentUniversity.location,
        university_email: currentUniversity.university_email,
        university_address: currentUniversity.university_address,
        bg_image_url: currentUniversity.bg_image_url, // Assuming image is a URL for edit
        created_by: currentUniversity.created_by,
      });
    }
  }, [isEditMode, currentUniversity]);

  const handleInputChange = async (e) => {
    const { name, value, files } = e.target;
    
    if (name === 'contact_number') {
      // Validate the contact number to be digits only
      if (!/^\d+$/.test(value)) {
        setContactNumberError('Contact number should only contain digits.');
      } else if (value.length !== 10) {
        setContactNumberError('Contact number should be exactly 10 digits.');
      } else {
        setContactNumberError('');
      }
    }

    if (name === 'university_email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setEmailError('Please enter a valid email address.');
      } else {
        setEmailError('');
      }
      
    }

    if (name === 'logo_url' || name === 'bg_image_url') {
      let file = files[0];
      const base64String = await FileToBase64(file);
      setFormData({
        ...formData,
        [name]: base64String, // For file inputs, save the selected file object
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (contactNumberError) {
      return; // Don't submit if there's a validation error
    }

    // Call the parent component's onSubmit function
    onSubmit(formData);
    if(isEditMode) {
      let response = await UPDATEUNIVERSITY(formData);
      if(response.data.status == 'failure') {
        alert(response.data.message);
      }
    } else {
      let response = await ADDUNIVERSITY(formData);
      if(response.data.status == 'failure') {
        alert(response.data.message);
      }
    }
  };

  return (
    <div className="modal show" style={{ display: 'block' }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content py-0 px-0">
          <div className="modal-header modelheader">
            <h5 className="modal-title modaltitle">
              {isEditMode ? 'Edit University' : 'Add University'}
            </h5>
            <button type="button" className="btn-close btnclose" onClick={onClose}>×</button>
          </div>
          <div className="modal-body model-popupPad">
            <form onSubmit={handleSubmit}>
              <div className='row'>
              <div className="col-12 col-lg-4 mb-4">
                <label className="formlabel">University Name<span className='imp'>*</span></label>
                <input
                  type="text"
                  name="university_name"
                  value={formData.university_name}
                  onChange={handleInputChange}
                  className="form-control fromFilespop"
                  required
                />
              </div>
              <div className="col-12 col-lg-4 mb-4">
                <label className="formlabel">University Code<span className='imp'>*</span></label>
                <input
                  type="text"
                  name="university_code"
                  value={formData.university_code}
                  onChange={handleInputChange}
                  className="form-control fromFilespop"
                  required
                />
              </div>
              <div className="col-12 col-lg-4 mb-4">
                <label className="formlabel">University Type<span className='imp'>*</span></label>
                <input
                  type="text"
                  name="university_type"
                  value={formData.university_type}
                  onChange={handleInputChange}
                  className="form-control fromFilespop"
                  required
                />
              </div>
              <div className="col-12 col-lg-6 mb-4">
                <label className="formlabel">Contact Number<span className='imp'>*</span></label>
                <input
                  type="text"
                  name="contact_number"
                  value={formData.contact_number}
                  onChange={handleInputChange}
                  className="form-control fromFilespop"
                  maxlength="10"
                  required
                />
                {contactNumberError && (
                  <div className="text-danger">{contactNumberError}</div>
                )}
              </div>
              
              <div className="col-12 col-lg-6 mb-4">
                <label className="formlabel">Location<span className='imp'>*</span></label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  className="form-control fromFilespop"
                  required
                />
              </div>
              <div className="col-12 col-lg-6 mb-4">
                <label className="formlabel">University Email<span className='imp'>*</span></label>
                <input
                  type="email"
                  name="university_email"
                  value={formData.university_email}
                  onChange={handleInputChange}
                  className="form-control fromFilespop"
                  required
                />
                {emailError && (
                  <div className="text-danger">{emailError}</div>
                )}
              </div>
              <div className="col-12 col-lg-6 mb-4">
                <label className="formlabel">University Address<span className='imp'>*</span></label>
                <input
                  type="text"
                  name="university_address"
                  value={formData.university_address}
                  onChange={handleInputChange}
                  className="form-control fromFilespop"
                  required
                />
              </div>
              <div className="row col-12 col-lg-12 mb-4">
                <div className='col-12 col-lg-6'>
                <label className="formlabel">Logo</label>
                <input
                  type="file"
                  name="logo_url"
                  onChange={handleInputChange}
                  className="form-control fromFilespop"
                  accept="image/*"
				          
                />
              </div>
              <div className='col-12 col-lg-6'>
              <label className="formlabel">Background Image</label>
                <input
                  type="file"
                  name="bg_image_url"
                  onChange={handleInputChange}
                  className="form-control fromFilespop"
                  accept="image/*"
				  
                />
                </div>
              </div>
              <div className="row col-12 col-lg-12 mb-4">
                <div className='col-12 col-lg-6'>
                {formData.logo_url && (
                <div>
                  <img 
                    src={formData.logo_url} 
                    alt="Logo Preview" 
                    style={{ maxWidth: '100%', maxHeight: '150px', marginBottom: '10px' }} 
                  />
                </div>
              )}
                </div>
                <div className='col-12 col-lg-6'>
               
                {formData.bg_image_url && (
                  <div>
                    <img 
                      src={formData.bg_image_url} 
                      alt="Background Image Preview" 
                      style={{ maxWidth: '100%', maxHeight: '150px', marginBottom: '10px' }} 
                    />
                  </div>
                )}
                </div>
              </div>
             
              </div>
              <div className='text-center mt-mb'>
              <button type="submit" className="btn updatebtn">
                {isEditMode ? 'Update University' : 'Add University'}
              </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversityForm;
