import React, { useEffect,useState, useRef } from 'react';
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';
import HomePageFooter from '../LoginComponents/HomePageFooter';
import {GET_APPLICANT_DATA} from './HomeServices';
import PreviewApplication from './PreviewApplication';
import './HomeScreen.css';
import { universityData } from '../LoginComponents/LoginServices';
import { UNIVERSITY_CODE } from '../../config';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content previewcontent">
        <button className="close-button" onClick={onClose}>X</button>
        {children}
      </div>
    </div>
  );
};

const HomeScreen = () => {
  const navigate = useNavigate();
  const username = sessionStorage.getItem('name');
  const [formId, setFormId] = useState(null);
    // Get from session or state
  let [response,setResponse] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const printRef = useRef();
  const [universityDetails, setUniversityDetails] = useState({});
  const [applicationId, setApplicationId] = useState('');
  const [affiliationCode, setAffiliationCode] = useState('');

  const handlePrint = (applicant_id, affiliation_code) => {
    // Set application details in session storage
    sessionStorage.setItem('application_id', applicant_id);
    sessionStorage.setItem('affiliation_code', affiliation_code);
  
    // Update the state to trigger re-render of PreviewApplication
    setApplicationId(applicant_id);
    setAffiliationCode(affiliation_code);
  
    // Delay the print function to ensure DOM updates are complete
    setTimeout(() => {
      if (printRef.current) {
        const printContent = printRef.current.innerHTML;
        const originalContent = document.body.innerHTML;
  
        document.body.innerHTML = printContent;
        window.print();
        document.body.innerHTML = originalContent;
        window.location.reload(); // Reload to reapply original styles and content
      }
    }, 100); // Short delay to allow DOM updates
  };
  

    useEffect(()=>{
      async function get_appl_data (){
        let payload = {
          mobile : sessionStorage.getItem('mobile'),
          //process.env.UNIVERSITY
          university_code: "JNTU"
        }
      
        const res = await GET_APPLICANT_DATA(payload);
        if(res.data.status == 'failure') {
          alert(res.data.message);
        } else {
          setResponse(res.data);
        }
      }

      get_appl_data()
    },[formId])

    useEffect(() => {
      const fetchUniversityDetails = async () => {
            let payload = {
                  university_code: UNIVERSITY_CODE,
          };
          const response = await universityData(payload);
          if(response.data.status == 'failure') {
            alert(response.data.message);
          } else {
            setUniversityDetails(response.data.message[0]);
          }
        
      };
      fetchUniversityDetails();
    }, []);


    const handleApplyNow = (form_id,applicant_id,appl_status,last_page,affiliation_code) => {
      sessionStorage.setItem('application_id',applicant_id)   
      sessionStorage.setItem('affiliation_code',affiliation_code)

      if (form_id) {
        navigate(`/profile/${form_id}`,{
          state: {
            application_status: appl_status,
            last_page: last_page,
            affiliation_code: affiliation_code
          }
      }); 
      } else {
        console.error("Form ID is not available.");
      }
    };

    const handlePreview = (applicant_id, affiliation_code) => {
      sessionStorage.setItem('application_id',applicant_id);
      sessionStorage.setItem('affiliation_code',affiliation_code)
      setIsModalOpen(true);
    };

  const closeModal = () => {
    setIsModalOpen(false);
  };
    
  return (

    
    <div>
      <Navbar username={username} universityDetails={universityDetails} />
      <div className="container">
        <div className='containerWrap'>
      <div className="titleheader">
        <h2>Affiliation Details</h2>
      </div>
      {response.map(function(object, i){
        return <div className="content2">
        <div className="program-title">{object.affiliation_code}</div>
        <div className="steps">
          <div className='step completed'>
            Step 1 <br />
            <span className="step-label">Registration</span>
            <span className="arrow">↵</span>
          </div>
          <div className={`${object.appl_status == "Verified" || object.appl_status == "Documents Uploaded" || object.appl_status == "Applicant Details Submitted" ? 'step completed' : 'step'}`}>
            Step 2 <br />
            <span className="step-label">Application Form</span>
          </div>
          <div className={`${object.appl_status == "Verified" || object.appl_status == "Documents Uploaded" ? 'step completed' : 'step'}`}>
            Step 3 <br />
            <span className="step-label">Document Upload</span>
          </div>
          <div className={`${object.appl_status == "Verified" ? 'step completed' : 'step'}`}>
            Step 4 <br />
            <span className="step-label">Payment</span>
          </div>
        </div>
        <div className="instructions">
          <h3>Instructions :</h3>
          <ul>
            <li>Read all the fields carefully and then respond.</li>
            <li>Fill up all the relevant fields in the application form.</li>
          </ul>
        </div>
        <div className="apply-btn">
          <button onClick={ () => handleApplyNow(object.form_id,object.applicant_id,object.appl_status,object.last_page,object.affiliation_code) }>
          {
                  object.appl_status === 'Applicant Details Submitted' ? 'Upload Documents' :
                    object.appl_status === 'Documents Uploaded' ? 'Pay Now' :
                      'Apply Now'
                }
          </button>
          <button style={{display:`${object.appl_status != "Documents Uploaded" ? 'none' : ''}`}} onClick={() => handlePreview(object.applicant_id, object.affiliation_code)}>Preview Application</button>
          <button style={{display:`${object.appl_status != "Documents Uploaded" ? 'none' : ''}`}} onClick={() => handlePrint(object.applicant_id, object.affiliation_code)}>Print</button>

        </div>
        
      </div>
    })}

      
    </div>
    </div>

    {/* Modal with PreviewApplication component as children */}
    <Modal isOpen={isModalOpen} onClose={closeModal}>
        <PreviewApplication />
      </Modal>

    {/* Hidden div containing the PreviewApplication component to print */}
    <div style={{ display: 'none' }} ref={printRef}>
      {applicationId && (
        <PreviewApplication id={applicationId} affiliation_code={affiliationCode} />
      )}
    </div>

      <HomePageFooter universityDetails={universityDetails}/>
    </div>
    
  );
};

export default HomeScreen;
