import {HttpClient} from '../../http-service/api';
import {API_LIST} from '../../http-service/list';


export const LOGIN = (payload) => {
    return HttpClient.post(API_LIST.LOGIN,payload);
}

export const Register = (payload) => {
    return HttpClient.post(API_LIST.Register,payload);
}

export const changePassword = (payload) => {
    return HttpClient.post(API_LIST.CHANGEPASSWORD,payload);
}

export const universityData = (payload) => {
    return HttpClient.post(API_LIST.UNIVERSITYDATA,payload);
}