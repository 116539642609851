import React, { useState, useEffect } from 'react';
import {GETFORMS} from './FormComponents/FormServices';

const AddaffiliationForm = ({ isEditMode, currentAffiliation, onClose, onSubmit }) => {

  const [formDropDown, setFormDropDown] =useState([])

  useEffect(() => {
    async function fetchData() {
      let res = await GETFORMS();
			if(res.data.status == 'failure') {
        alert(res.data.message);
      } else {
        setFormDropDown(res.data.message);
      }
    	
    }
    fetchData();
  }, []);
  
    const [formData, setFormData] = useState({
        //id :0,
        affiliation_name: "",
        affiliation_code: "",
        affiliation_desc: "",
        university_code: "",
        duration: "",
        form_id: '',
        apply_start_date: null,
        apply_end_date: null,
    });

    useEffect(() => {
        if (isEditMode && currentAffiliation) {
      // Populate form data if in edit mode
      setFormData({
            id : currentAffiliation.id,
            affiliation_name: currentAffiliation.affiliation_name,
            affiliation_code: currentAffiliation.affiliation_code,
            affiliation_desc: currentAffiliation.affiliation_desc,
            university_code: currentAffiliation.university_code,
            duration: currentAffiliation.duration,
            form_id: currentAffiliation.form_id,
            apply_start_date: currentAffiliation.apply_start_date,
            apply_end_date: currentAffiliation.apply_end_date,
      });
    }
  }, [isEditMode, currentAffiliation]);


  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    
  };

  const handleInputChange = (e) => {
    const { name, value} = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
  };

  return (
    <div className="modal show" style={{ display: 'block' }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content py-0 px-0">
          <div className="modal-header modelheader">
            <h5 className="modal-title modaltitle">
              {isEditMode ? 'Edit Affiliation' : 'Add Affiliation'}
            </h5>
            <button type="button" className="btn-close btnclose" onClick={onClose}>×</button>
          </div>
          <div className="modal-body model-popupPad">
            <form onSubmit={handleSubmit}>
              {isEditMode &&
                <input
                  type="hidden"
                  name="id"
                  value={formData.id}
                />
              }
			  <div className='row'>
				<div className="col-12 col-lg-6 mb-4">
					<label className="formlabel">Affiliation Name <span className='imp'>*</span></label>
					<input
					type="text"
					name="affiliation_name"
					value={formData.affiliation_name}
					onChange={handleInputChange}
					className="form-control fromFilespop"
					required
					/>
				</div>
				<div className="col-12 col-lg-6 mb-4">
					<label className="formlabel">Affiliation Code <span className='imp'>*</span></label>
					<input
					type="text"
					name="affiliation_code"
					value={formData.affiliation_code}
					onChange={handleInputChange}
					className="form-control fromFilespop"
					required
					/>
				</div>
				<div className="col-12 col-lg-6 mb-4">
					<label className="formlabel">Affiliation Description <span className='imp'>*</span></label>
					<input
					type="text"
					name="affiliation_desc"
					value={formData.affiliation_desc}
					onChange={handleInputChange}
					className="form-control fromFilespop"
					required
					/>
				</div>
				<div className="col-12 col-lg-6 mb-4">
					<label className="formlabel">University Code <span className='imp'>*</span></label>
					<input
					type="text"
					name="university_code"
					value={formData.university_code}
					onChange={handleInputChange}
					className="form-control fromFilespop"
					required
					/>
				</div>
				<div className="col-12 col-lg-6 mb-4">
					<label className="formlabel">Copy Template From</label>
					<select
					name="form_id"
					value={formData.form_id}
					onChange={handleInputChange}
					className="form-control fromFilespop"
					disabled={isEditMode}
					>
					<option value="">Select form template</option>
					{formDropDown.map((option) => (
						<option key={option.id} value={option.id}>
						{option.name}
						</option>
					))}
					</select>
				</div>
				<div className="col-12 col-lg-6 mb-4">
					<label className="formlabel">Apply Start Date <span className='imp'>*</span></label>
					<input
					type="date"
					name="apply_start_date"
					value={formData.apply_start_date}
					onChange={handleInputChange}
					className="form-control fromFilespop"
					required
					/>
				</div>
				<div className="col-12 col-lg-6 mb-4">
					<label className="formlabel">Apply End Date <span className='imp'>*</span></label>
					<input
					type="date"
					name="apply_end_date"
					value={formData.apply_end_date}
					onChange={handleInputChange}
					className="form-control fromFilespop"
					required
					/>
				</div>
			</div>
			<div className='text-center mt3-mb2'>
			<button type="submit" className="btn updatebtn">
                {isEditMode ? 'Update Affiliation' : 'Add Affiliation'}
              </button>
			</div>
              
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddaffiliationForm;
