// PreviewApplication.js
import React, { useEffect, useState } from 'react';
import {previewData} from './HomeServices';
import { universityData } from '../LoginComponents/LoginServices';
import { UNIVERSITY_CODE } from '../../config';
import alogo from '../../img/alogo.png';

const PreviewApplication = ({id, affiliation_code}) => {
	const [data, setData] = useState(null); // State to store API data
  	const [loading, setLoading] = useState(true);
	const [universityDetails, setUniversityDetails] = useState({});
	const aff_code = sessionStorage.getItem("affiliation_code") || affiliation_code;

	useEffect(() => {
		const fetchData = async () => {
		try {
			let payload = {
				applicant_id: sessionStorage.getItem("application_id") || id
			};
			const response = await previewData(payload);
			if(response.data.status == 'failure') {
				alert(response.data.message);
			} else {
				console.log(response.data.message);
				setData(response.data.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setLoading(false);
		}
		};
	
		fetchData();

		const fetchUniversityDetails = async () => {
			let payload = {
				university_code: UNIVERSITY_CODE,
			};
			const response = await universityData(payload);
			if(response.data.status == 'failure') {
				alert(response.data.message);
			} else {
				setUniversityDetails(response.data.message[0]);
			}
				
			};
		fetchUniversityDetails();
	}, []);
	
	const capitalizeFirstLetter = (str) => {
		// Split at the first occurrence of an opening parenthesis
		const [mainText, parenthesesText] = str.split(/ *\(/);
		
		// Capitalize each word in the main text, replacing underscores with spaces
		const formattedMainText = mainText
		  .toLowerCase()
		  .split('_')
		  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
		  .join(' ');
	  
		// Return formatted text with parentheses text, if any
		return parenthesesText
		  ? `${formattedMainText} (${parenthesesText}`
		  : formattedMainText;
	};
	  

	if (loading) return <p>Loading...</p>;

	const renderDataSection = (title, data) => (
		<div>
		  <h3 className="mb-5 mt-5" style={{ textDecoration: 'underline' }}>{title}</h3>
		  <div className="row">
			{Object.entries(data).map(([key, value]) => {
			  if (title === "Other Details") {
				return (
				  <div className="col-xs-12 col-lg-12 mb-3" key={key}>
					<span style={{ fontWeight: 'bold' }}>{capitalizeFirstLetter(key)} :</span> {value || 'NA'}
				  </div>
				);
			  }
			  
			  // For other titles, display the content as usual
			  return (
				<div className="col-xs-12 col-lg-4 mb-3" key={key}>
				  <span style={{ fontWeight: 'bold' }}>{capitalizeFirstLetter(key)} :</span> {value || 'NA'}
				</div>
			  );
			})}
		  </div>
		</div>
	  );
	  
	  const renderCategoryTable = (title, categoryData) => (
		<div>
			<h3 className="mb-5 mt-5" style={{ textDecoration: 'underline' }}>{title}</h3>
			<table className="table table-bordered">
				<thead>
					<tr>
						{Object.keys(categoryData[0]).map((key, index) => (
							<th key={index}>{key}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{categoryData.map((row, rowIndex) => (
						<tr key={rowIndex}>
							{Object.values(row).map((value, colIndex) => (
								<td key={colIndex}>{value || 'NA'}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
	return (
		<div>
		  {/* <table style={{ width: "100%" }}>
			<tbody>
			  <tr style={{ width: "100%" }}>
				<th style={{ width: "18%", textAlign: "center" }}>
				  <img
					style={{ margin: "2%" }}
					src={universityDetails ? universityDetails.logo_url : alogo} 
					alt="University Logo"
				  />
				</th>
				<th style={{ textAlign: "center", width: "100%" }}>
				  <h2>{universityDetails.university_name || 'University Name'}, {universityDetails.location || 'Location'}</h2>
				  <h4>DEFD Application Form</h4>
				  
				</th>
			  </tr>
			</tbody>
		  </table> */}
		  
			<div className='row col-12 col-lg-12 text-center'>
				<div className='col-12 col-lg-2'>
				<img
					style={{ margin: "2%" }}
					src={universityDetails ? universityDetails.logo_url : alogo} 
					alt="University Logo"
				  />
				</div>
				<div className='col-12 col-lg-10'>
					<h2>{universityDetails.university_name || 'University Name'}, {universityDetails.location || 'Location'}</h2>
					<h4>{aff_code} Application Form</h4>
				</div>
			</div>
		 
		  <hr></hr>
		  <div style={{textAlign: "left"}}>
		  {data?.applicant_data && renderDataSection('Land Details', data.applicant_data)}
      {data?.institute_details && renderDataSection('Facility Details', data.institute_details)}
      {data?.category_wise_employee_data?.length > 0 &&
        renderCategoryTable('Category wise Employees', data.category_wise_employee_data)}
      {data?.faculty_data?.length > 0 && renderCategoryTable('Faculty Details', data.faculty_data)}
      {data?.category_wise_student_data?.length > 0 &&
        renderCategoryTable('Category wise Students', data.category_wise_student_data)}
      {data?.state_wise_data?.length > 0 && renderCategoryTable('State wise Students', data.state_wise_data)}
      {data?.other_details && renderDataSection('Other Details', data.other_details)}
		  </div>
		</div>
	  );
};

export default PreviewApplication;
