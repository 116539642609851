import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import HomePageHeader from './HomePageHeader';
import HomePageFooter from './HomePageFooter';
import Loginvry from '../../img/userverification.png';
import Captcha from './Captcha';
import './Captcha.css'; // Import CSS for styling
import { LOGIN } from './LoginServices';
import { universityData } from './LoginServices';
import { UNIVERSITY_CODE } from '../../config';

const body = {
	backgroundColor: '#fff' 
}

const ApplicantLogin = ({ updateUserRole }) => {
	
	const [formValues, setFormValues] = useState({ mobile: '', password: '' });
  	const [errors, setErrors] = useState({});
  	const [captchaVerified, setCaptchaVerified] = useState(false);
	const navigate = useNavigate();
  	const [universityDetails, setUniversityDetails] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [captchaKey, setCaptchaKey] = useState(Date.now());

    useEffect(() => {
        const fetchUniversityDetails = async () => {
              let payload = {
                    university_code: UNIVERSITY_CODE,
            };
            const response = await universityData(payload);
						if(response.data.status == 'failure') {
							alert(response.data.message);
						} else {
            	setUniversityDetails(response.data.message[0]);
						}
          
        };
        fetchUniversityDetails();
      }, []);

	const handleAlertClose = () => {
        setCaptchaKey(Date.now()); 
    };

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		if (name === 'mobile') {
			const numericValue = value.replace(/[^0-9]/g, ''); 
			setFormValues({
				...formValues,
				[name]: numericValue,
			});
		} else {
			setFormValues({
				...formValues,
				[name]: value,
			});
		}
	};
	const handleCaptchaVerified = (isVerified) => {
		setCaptchaVerified(isVerified);
	};

	const validate = () => {
		let errors = {};
		if (!formValues.mobile) {
		  errors.mobile = 'Mobile is required';
		} else if (formValues.mobile.length !== 10) {
			errors.mobile = 'Mobile number must be 10 digits long';
		}
		
		if (!formValues.password) {
		  errors.password = 'Password is required';
		}
		if (!captchaVerified) {
		  errors.captcha = 'Invalid Captcha';
		}
		setErrors(errors);
		return Object.keys(errors).length === 0;
	};
	const onFinish = async() => {
		let payload = {
				user_name: formValues.mobile,
				pass: formValues.password,
		};
		try {
			let loginResponse = await LOGIN(payload);
			
			if(loginResponse.data.status == 'failure') {
				alert(loginResponse.data.message);
				handleAlertClose();
			} else {
				const role = loginResponse.data.message[0].role;
				updateUserRole(role);
				sessionStorage.setItem("mobile",formValues.mobile);
				sessionStorage.setItem("name",loginResponse.data.message[0].full_name);
				sessionStorage.setItem("fullname",loginResponse.data.message[0].full_name);
				sessionStorage.setItem('token', loginResponse.data.message[0].token);
				navigate('/home');
			}
				
		} catch (error) {
			alert('An unexpected error occurred. Please try again later.');
            handleAlertClose();
		} 
	}
	const handleSubmit = (event) => {
		event.preventDefault();
	
		if (validate()) {
			setIsSubmitting(true);
		  	onFinish();
			setIsSubmitting(false);
		} 
	};
 	return (
		<>
			<div className='MainDiv' style={body}>
				<div className='AppltBg mb-5'>
					<HomePageHeader universityDetails={universityDetails}/>
				</div>
				<div className='containerfluid frmPoatRlvt'>
					<div className='row'>
						<div className='col-xs-12 col-lg-7'>
							<div className='pt-5 pb-3'>
								<div className='untyTitle'>
									<h1>General Instructions</h1>
								</div>
							</div>
							<div className='mt-5'>
								<div className='tmcTitle pt-5'>
								<h1>The Candidates applying online may</h1>
								</div>
								<div className='tmcList'>
								<ul>
									<li>Read the brochure, eligibility criteria and availability of Seats Category-wise thoroughly before filling the application form.</li>
									<li>Keep all the information ready before starting to fill the Application Form.</li>
									<li>Keep ready the scanned (digital) images of your Photograph, Signature, 10th, 12th, Residence/Domicile and Caste Certificate (if applicable).</li>
									<li>OBC Candidates have to upload caste certificate as per norms of govt of Odisha reservation.</li>
									<li>Please note your Application Number for future reference.</li>
									<li>Incomplete application form will be rejected.</li>
									<li>After completion of the application form download the print application.</li>
								</ul>
								</div>
							</div>
						</div>
						<div className='col-xs-12 col-lg-5'>
							<div className='AllLgnBg'>
								<div className='AppTitle'>
									<h1>Applicant Login</h1>
								</div>
								<div className='p-2 mt-5'>
									<div className='col-xs-12 col-lg-12'>
										<div className='text-center'>
											<div className='lgnnvry'>
												<img src={Loginvry} alt="Login Verification" />
											</div>
										</div>
									</div>
									<form onSubmit={handleSubmit}>
									<div className='row'>
										<div className='col-xs-12 col-lg-12'>
											<div className="mb-4">
												<label htmlFor="mobile" className="form-label">Mobile<span className='imp'>*</span></label>
												<input
													type="text"
													className="form-control fromFiles"
													name="mobile"
													id="mobile"
													aria-describedby="helpId"
													placeholder="Enter your Mobile number"
													value={formValues.mobile}
													onChange={handleInputChange}
													maxLength={10}
												/>
												{errors.mobile && <span style={{ color: 'red' }}>{errors.mobile}</span>}
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-xs-12 col-lg-12'>
											<div className="mb-4">
												<label htmlFor="password" className="form-label">Password<span className='imp'>*</span></label>
												<input
													type="password"
													className="form-control fromFiles"
													name="password"
													id="password"
													aria-describedby="helpId"
													placeholder="Enter your Password"
													value={formValues.password}
          											onChange={handleInputChange}
												/>
												{errors.password && <span style={{ color: 'red' }}>{errors.password}</span>}
											</div>
										</div>
									</div>
										<div className='row mb-5'>
											<Captcha 
												key={captchaKey}
												onCaptchaVerified={handleCaptchaVerified} 
											/>
											{errors.captcha && <span style={{ color: 'red' }}>{errors.captcha}</span>}
										</div>
										<div className='row'>
											<div className='col-xs-12 col-lg-12 text-center'>
												<button
													type="submit"
													className="btn saveBtn widthBtn"
													disabled={isSubmitting}
												>
													Login
												</button>
											</div>
										</div>
										{/* <div className='row'>
											<div className='col-xs-12 col-lg-12 text-center'>
												<a href = "#" onClick={()=>{navigate('/change-password')}}>
													Forgot your password?
												</a>
											</div>
										</div> */}
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<HomePageFooter universityDetails={universityDetails}/>
			</div>
		</>
  	);
};

export default ApplicantLogin;
