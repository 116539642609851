

import React, { useState, useEffect } from 'react';
import HomePageHeader from './HomePageHeader';
import HomePageFooter from './HomePageFooter';
import Captcha from './Captcha';
import './Captcha.css'; // Import CSS for styling
import { LOGIN } from './LoginServices';
import { useNavigate } from 'react-router-dom';
import { universityData } from './LoginServices';
import { UNIVERSITY_CODE } from '../../config';

const UniversityLogin = ({ updateUserRole }) => {
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [formValues, setFormValues] = useState({ username: '', password: '' });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [universityDetails, setUniversityDetails] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
	const [captchaKey, setCaptchaKey] = useState(Date.now());

    useEffect(() => {
        const fetchUniversityDetails = async () => {
              let payload = {
                    university_code: UNIVERSITY_CODE,
            };
            const response = await universityData(payload);
            if(response.data.status == 'failure') {
                alert(response.data.message);
              } else {
                setUniversityDetails(response.data.message[0]);
              }
          
        };
        fetchUniversityDetails();
      }, []);
    const handleCaptchaVerified = (isVerified) => {
        setCaptchaVerified(isVerified);
    };

    const handleAlertClose = () => {
        setCaptchaKey(Date.now()); 
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const validate = () => {
        let errors = {};
        if (!formValues.username) {
            errors.username = 'Username is required';
        }
        if (!formValues.password) {
            errors.password = 'Password is required';
        }
        if (!captchaVerified) {
            errors.captcha = 'Invalid Captcha';
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onFinish = async() => {
        let payload = {
            user_name: formValues.username,
            pass: formValues.password,
        };
        try {
            let loginResponse = await LOGIN(payload);
            
            if (loginResponse.data.status === "failure") {
                alert(loginResponse.data.message);
				handleAlertClose();
            } else{
                const role = loginResponse.data.message[0].role;
                updateUserRole(role);
                sessionStorage.setItem("user",formValues.username);
                sessionStorage.setItem("fullname",loginResponse.data.message[0].full_name);
                sessionStorage.setItem('token', loginResponse.data.message[0].token);
                navigate('/dashboard');
            }
            
        } catch (error) {
            alert('An unexpected error occurred. Please try again later.');
            handleAlertClose();
        } 
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (validate()) {
            setIsSubmitting(true);
            onFinish();
            setIsSubmitting(false);
        } 
    };
    return (
        <>
            <div className='MainDiv'>
                <div className='AppltBg clearfix AppltBgHeigth'>
                    <HomePageHeader universityDetails={universityDetails} />
                    <div className='containerfluidForm p-5'>
                        <div className='row'>
                            <div className='col-xs-12 col-lg-5'>
                                <div className='row'>
                                    <div className='col-xs-12 col=lg-12 text-center p-0'>
                                        <div className='unLgnTitle mt-5'>
                                            <h1>
                                                Affiliation Portal
                                            </h1>
                                        </div>
                                        <div className='unLgnTitle'>
                                            <h2>
                                                Redefining Career Enrolment
                                            </h2>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col=lg-12 text-center'>
                                        <img className='img-fluid mt-5' src={require('../../img/logsImgPe.png')} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-xs-12 col-lg-7'>
                                <div className='loginUnBg'>
                                    <div className="AppTitle"><h1>University Login</h1></div>
                                    <form onSubmit={handleSubmit}>
                                        <div className='col-xs-12 col-lg-12 mt-5 pt-5'>
                                            <div className="mb-4">
                                                <label htmlFor="" className="form-label">User Name<span className='imp'>*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control fromFiles"
                                                    name="username"
                                                    id="username"
                                                    aria-describedby="helpId"
                                                    placeholder="Enter your username"
                                                    value={formValues.username}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.username && <span style={{ color: 'red' }}>{errors.username}</span>}
                                            </div>
                                        </div>
                                        <div className='col-xs-12 col-lg-12'>
                                            <div className="mb-4">
                                                <label htmlFor="" className="form-label">Password<span className='imp'>*</span></label>
                                                <input
                                                    type="password"
                                                    className="form-control fromFiles"
                                                    name="password"
                                                    id="password"
                                                    aria-describedby="helpId"
                                                    placeholder="Enter your Password"
                                                    value={formValues.password}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.password && <span style={{ color: 'red' }}>{errors.password}</span>}
                                            </div>
                                        </div>
                                        <div className='col-xs-12 col-lg-12'>
                                        <div className='row mb-3'>
                                            <Captcha
                                                key={captchaKey}
                                                onCaptchaVerified={handleCaptchaVerified}
                                            />
                                            {errors.captcha && <span style={{ color: 'red' }}>{errors.captcha}</span>}
                                        </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-xs-12 col-lg-12 text-center mb-5 mt-5'>
                                                <button
                                                    type="submit"
                                                    className="btn saveBtn widthBtn"
                                                    disabled={isSubmitting}
                                                >
                                                    Login
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HomePageFooter universityDetails={universityDetails} />
        </>
    )
}

export default UniversityLogin
